.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .67);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer {
    margin: 1rem !important;
}

.formInput {
    margin: 0.5rem !important;
}

.form {
    margin: 1rem;
}

.sendButton {
    margin: 1rem !important;
}