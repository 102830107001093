.header {
    display: flex;
}

.edit-button {
    padding-left: 2rem;
    color: var(--rhythm-color, #5E6E83) !important;
}

.edit-button:hover {
    color: var(--nightBlue-color, #0C0042) !important;
    cursor: pointer;
}