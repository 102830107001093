.card {
  margin: 0.5rem;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editIcon {
  cursor: pointer;
}

.cardContent {
  padding: 1rem;
}

.checkbox {
  margin-left: 2rem;
}

.fields {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 20rem;
  margin-top: 1rem;
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.67);
  z-index: 100;
}
