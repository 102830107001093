.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .67);
    z-index: 100;
}

.tableHeaderCells {
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableCells {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableCellDescription {
    min-width: 20rem;
    max-height: 1rem;
}

.tableRowGroup {
    display: table-row-group;
}

.tableRow {
    display: table-row;
}

.tableContainer {
    margin-inline: 1rem;
}