.list-item-container {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem 1.2rem 0.8rem 1rem !important;
}

.description {
    flex: 1;
}

.remove-button-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.remove-button {
    margin-left: 1rem;
    margin-right: 0.5rem;
    background-color: var(--white-color, #ffffff) !important;
    color: var(--roseRed100-color, #F04159) !important;
}

.remove-button:hover {
    color: var(--roseRed50-color, #FFA0AD) !important;
}