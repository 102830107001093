/* --------------------Spacelist-------------------- */
i.icon.fileIcons:before {
  display: none;
}
.filestreamContainer i.icon.fileIcons {
  font-size: 2.4em;
  cursor: pointer;
}
.infostreamContainer i.icon.fileIcons,
.infostreamContainer .ui.items > .item > .image:not(.ui) {
  margin-right: 4px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  min-width: 32px;
  min-height: 40px;
}
.infostreamContainer .ui.items > .item > .image + .content {
  padding-left: 0;
}
.fileIcons.default {
  background: url('../../assets/icons/default.svg') no-repeat;
}
.fileIcons.archive {
  background: url('../../assets/icons/archive.svg') no-repeat;
}
.fileIcons.audio {
  background: url('../../assets/icons/audio.svg') no-repeat;
}
.fileIcons.code {
  background: url('../../assets/icons/code.svg') no-repeat;
}
.fileIcons.document {
  background: url('../../assets/icons/document.svg') no-repeat;
}
.fileIcons.images {
  background: url('../../assets/icons/images.svg') no-repeat;
}
.fileIcons.pdf {
  background: url('../../assets/icons/pdf.svg') no-repeat;
}
.fileIcons.presentation {
  background: url('../../assets/icons/presentation.svg') no-repeat;
}
.fileIcons.spreadsheet {
  background: url('../../assets/icons/spreadsheet.svg') no-repeat;
}
.fileIcons.system {
  background: url('../../assets/icons/system.svg') no-repeat;
}
.fileIcons.text {
  background: url('../../assets/icons/text.svg') no-repeat;
}
.fileIcons.video {
  background: url('../../assets/icons/video.svg') no-repeat;
}
.fileIcons.vector {
  background: url('../../assets/icons/vector.svg') no-repeat;
}
.invitationList {
  width: 380px;
  min-width: 380px;
  border-right: 1px solid var(--gainsboro-color, #dcdcdc);
  border-bottom: 1px solid var(--gainsboro-color, #dcdcdc);
  box-shadow: 0px 1px 10px var(--shadow-color, rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;
}
.invitationList .list .item {
  padding-bottom: 0.8em !important;
}
.invitationList .list .item .ui.grid {
  margin-top: 0;
}
.invitationList .blueTextLink {
  background: var(--ghostWhite-color, #E0E5EC);
  padding: 1em;
}
.invitationHeader {
  padding: 1em 0.5em 1em 2em !important;
}
.invitationHeader .header,
.ui.list .item .invitationHeader .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 244px;
}
.ui.list .item .invitationHeader .description {
  color: var(--rhythm-color, #5E6E83);
  word-break: break-all;
  margin: 0;
}
.invitationMessage {
  padding: 0.6em 1em 0em 1em;
}
.spaceList {
  width: 380px;
  min-width: 380px;
  overflow-y: auto;
  border-right: 1px solid var(--gainsboro-color, #dcdcdc);
  transition: min-width 0.3s ease, width 0.3s ease;
}
#scene-container .spaceListItem {
  padding: 0.6em 0.6em 0.6em 0;
  position: relative;
}
#scene-container .spaceList .ui.divided.items > .item:first-child {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em;
}
#scene-container .spaceList .ui.divided.items > .item:last-of-type {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
#scene-container .spaceList .content {
  display: block;
  padding: 0em 0em 0em 0.4em !important;
}
#scene-container .spaceList .badge {
  color: var(--white-color, #ffffff);
  background: var(--neonBlue-color, #5863F8);
  padding: 0.2em 0.7em;
  margin: 0 0 0 0.4em;
  float: right;
}
#scene-container .spaceList .header {
  font-size: 16px;
  color: var(--nightBlue-color, #0C0042);
  font-weight: 500;
  margin: 0;
  padding: 0;
  word-break: break-word;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 244px;
}
#scene-container .spaceListItem.active {
  background-color: var(--ghostWhite-color, #E0E5EC);
  box-shadow: 0px 1px 10px var(--shadow-color, rgba(0, 0, 0, 0.1)) inset;
  transition: all 0.3s ease;
}
.spaceList .spaceColor {
  width: 13px;
  height: auto;
  margin: -0.6em 0.4em -0.6em 0;
}
.spaceList .spaceColor:hover {
  opacity: 0.8;
}
#scene-container .spaceList .description {
  color: var(--rhythm-color, #5E6E83);
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(-5px);
}
.spaceList .ui.circular.label {
  display: none;
}
#scene-container .spaceList .companyName {
  max-width: 160px;
}
.spaceContainer .ui.list .column .row.description,
.spaceContainer .description,
.changelogList .description {
  color: var(--rhythm-color, #5E6E83);
  font-size: 12px;
}
.changelogHeader {
  display: inline-flex;
  width: 100%;
}
.changelogHeader span {
  margin-left: auto;
  line-height: 26px;
}
.ui.modal .header {
  word-break: break-all;
}
#company-container .ui.list .item .header {
  font-size: 16px;
  word-break: break-word;
  font-weight: normal;
  padding: 0.2em 0;
  line-height: normal;
}
/* --------------------End of Spacelist-------------------- */

/* --------------------SpaceContainer-------------------- */

.spaceContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  background-color: var(--ghostWhite-color, #E0E5EC);
}
.spaceTabs,
.spaceTabs .ui.bottom.attached.segment.active.tab,
#admin-scene .ui.bottom.attached.segment.active.tab
 {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: 0;
  position: relative;
}
#admin-scene .ui.bottom.attached.segment.active.tab {
  border-radius: 0;
}
#scene-container .spaceTabs .ui.attached.segment:not(.top) {
  position: absolute;
  width: 100%;
  top: 43px;
}
.spaceTabs .ui.attached.tabular.menu,
#admin-scene .ui.attached.tabular.menu {
  background-color: var(--ghostWhite-color, #E0E5EC);
}

#admin-scene .ui.tabular.menu .active.item {
  background-color: var(--aliceBlue-color, #E0E5EC);
}
.ui.tabular.menu .active.item {
  font-weight: 500 !important;
}
.spaceTabs .container .negative.message {
  margin-top: 2em;
}
/* --------------------End of SpaceContainer-------------------- */

/* --------------------SpaceHeader---------------------------------------- */

.spaceHeader {
  background-color: var(--ghostWhite-color, #E0E5EC);
  z-index: 1;
  display: flex;
  align-items: center;
}
#contact-scene .spaceHeader {
  display: flex;
}
#group-scene .spaceHeader,
#contact-scene .spaceHeader {
  padding: 14px 6px 8px 20px;
  height: 62px;

  align-items: center;
}
.spaceHeader .ui.header {
  margin: 0em 0.6rem 0em 0px;
  display: inline-flex;
}
.spaceHeader .ui.header span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 540px;
  cursor: default;
}
.spaceHeader .sub.header.description span {
  margin: 0 1.2em 0 0;
}
.spaceHeader .sub.header.description a {
  margin-left: 0.6em;
}
.spaceHeader .sub.header.description {
  display: inline;
  cursor: pointer;
  padding-top: 4px;
}
.memberInfo {
  cursor: pointer;
}
.filestreamContainer .ui.floating.dropdown .menu {
  right: 0;
  left: auto;
}
.spaceHeader .bell.icon,
.spaceHeader .bell.slash.icon,
.spaceHeader .edit.icon,
.spaceHeader .info.icon,
.spaceHeader .clock.outline.icon {
  color: var(--rhythm-color, #5E6E83);
}
.spaceHeader > .bell.slash.icon {
  color: var(--rhythm-color, #5E6E83);
  opacity: 0.5;
  margin-right: 8px;
}
.spaceHeader .close.icon {
  color: var(--roseRed100-color, #F04159);
}
.spaceHeader .membersIcon {
  background: url('../../assets/icons/group-members.svg') no-repeat;
}
.download.icon {
  cursor: pointer;
  color: var(--rhythm-color, #5E6E83);
}
.optionsIcon {
  background: url('../../assets/icons/option.svg') no-repeat;
  cursor: pointer;
}
#scene-container .verifiedIcon {
  background: url('../../assets/icons/verified-shield.svg') no-repeat;
  font-size: 1.3em;
  margin-right: 4px;
}
#scene-container .unverifiedIcon {
  background: url('../../assets/icons/unverified-shield.svg') no-repeat;
  font-size: 1.3em;
  margin-right: 4px;
}
.flowing.popup .header .verifiedIcon {
  background: url('../../assets/icons/verified-shield.svg') no-repeat;
  transform: translateY(2px);
}
.flowing.popup .header .unverifiedIcon {
  background: url('../../assets/icons/unverified-shield.svg') no-repeat;
  transform: translateY(2px);
}
.verifiedLabel.ui.label {
  background-color: transparent;
}
.verifiedLabel.ui.label:hover {
  background-color: transparent;
  border-color: transparent;
}
#scene-container .verifiedLabel.ui.label > .icon.verifiedIcon {
  transform: translate(-6px, -6px);
  font-size: 20px;
  height: 1.4em;
  width: 3em;
}
#scene-container .spaceList .item .verifiedIcon,
#scene-container .spaceList .item .unverifiedIcon {
  position: absolute;
  z-index: 100;
  left: 96%;
  margin: 0em 0em 0em -0.8em !important;
  font-size: 16px;
}

/* --------------------End of SpaceContainer-------------------- */

/* --------------------Spacedrawer Modals-------------------- */

.ui.modals.active .ui.modal > .header {
  border-bottom: none;
}
.ui.modals.active .destructive.ui.modal > .header {
  background-color: var(--roseRed100-color, #F04159);
  color: var(--white-color, #ffffff);
  font-weight: normal;
}
.ui.modal > .content > .description {
  color: var(--rhythm-color, #5E6E83);
  font-size: 0.92em;
  margin: 10px 0;
}
.ui.modals.active .warning.ui.modal > .header {
  background-color: var(--orange-color, #fcbb50);
  color: var(--white-color, #ffffff);
}
.keyIcon {
  background: url('../../assets/icons/key.svg') no-repeat 50%;
  background-size: 50%;
}
#space-drawer {
  right: 20px;
  top: 146px;
  position: absolute;
}
#space-drawer .fingerprintsymbol {
  background: url('../../assets/icons/fingerprintsymbol.svg') no-repeat 50%;
  font-size: 1.65em;
  transform: translate(-6px, 6px);
  margin-right: 0;
}
.groupMembersTable .ui.header > img + .content,
.ui.header > .image + .content {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.groupMembersTable.ui.table th.right {
  text-align: center;
  padding-left: 0;
}
.groupMembersTable.ui.table td.right {
  text-align: center;
}
.groupMembersTable .remove.icon {
  color: var(--roseRed100-color, #F04159);
  cursor: pointer;
}
.groupMembersTable .remove.icon:hover {
  color: var(--roseRed50-color, #FFA0AD);
}
.modalSubheader {
  margin-left: 10px;
  font-size: 16px;
  opacity: 0.8;
}
.modal .icon.deleteIcon {
  transform: translate(-1.4em, 0em);
}
.ownerLabel {
  text-transform: uppercase;
  font-size: 0.76em;
  letter-spacing: 0.2em;
  color: var(--rhythm-color, #5E6E83);
  font-weight: bold;
}
.groupMembersTable tbody:after {
  content: '-';
  display: block;
  line-height: 1.4em;
  color: transparent;
}
.groupMembersTable.ui.table tfoot th {
  border-top: none;
  width: 100%;
}
.groupMembersTable.ui.table tfoot p {
  font-size: 16px;
}
/* --------------------End of Spacedrawer Modals-------------------- */

/* --------------------Infostream Node-------------------- */

.infostreamNode,
.filestreamNode {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
i.icon.locationIcon {
  background: url('../../assets/icons/location.svg') no-repeat;
  font-size: 2.8em;
  margin: 0em;
  width: 1.2em;
}
/* --------------------End of Infostream Node-------------------- */

/* --------------------Uploader-------------------- */
.dropzone .dragNotification {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffffd4;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  z-index: 2;
  margin-bottom: 1rem;
}
.dropzone .dragNotification .icon {
  color: #909090;
}
.dropzone .dragNotification p {
  font-size: 18px;
  margin-top: 36px;
  color: #909090;
}
/* --------------------End of Uploader-------------------- */

/* --------------------Infostream-------------------- */

.infostreamContainer,
.filestreamContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.infostreamContainer .chatBubble .event a {
  color: var(--neonBlue-color, #5863F8);
  opacity: 0.8;
}
.infostreamContainer .chatBubble .event a:hover {
  opacity: 1;
}
.infostreamitem.in .emojiBubble {
  padding: 10px 0px;
  float: left;
}
.infostreamitem.out .emojiBubble {
  padding: 10px 12px;
  float: right;
  border: 1px solid transparent;
}
.bubbleText .emojiLine {
  font-size: 225%;
  padding-top: 10px;
}
.bubbleText code {
  display: block;
  white-space: pre;
  font-family: monospace !important;
  overflow-x: scroll;
  margin: 10px 0;
}
.bubbleText {
  display: inline;
  font-size: 16px;
  line-height: 1.4285em;
  text-align: left;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.chatBubble .bubbleText a.linkified {
  word-wrap: break-word;
  word-break: break-all;
  display: inline-flex;
}
.chatBubble {
  color: var(--nightBlue-color, #0C0042);
  padding: 10px 12px;
  min-width: 60px;
  max-width: 700px;
  transition: all 0.3s ease;
  word-wrap: break-word;
}
.infostreamitem.in .chatBubble {
  background: var(--ghostWhite-color, #E0E5EC);
  border-radius: 0px 16px 16px 16px;
  border: 1px solid var(--light-color, #e6e6e6);
  float: left;
}
.infostreamitem.out .chatBubble {
  background: var(--neonBlueLight-color, #eaf2ff);
  border: 1px solid var(--hawkesBlue-color, #d2daed);
  border-radius: 16px 16px 0px 16px;
  float: right;
}
.infostreamitem.out .destroyBubble {
  background: #fff6f6;
  border: 1px solid #e0b4b4;
  border-radius: 16px 16px 0px 16px;
  color: var(--shadowDark-color, rgba(0, 0, 0, 0.45));
  padding: 10px 12px;
  float: right;
  min-width: 60px;
  max-width: 700px;
  transition: all 0.3s ease;
  font-size: 16px;
}
.infostreamitem.out .destroyBubble .ui.label {
  background-color: transparent;
  transform: translate(-8px, -16px);
  color: var(--roseRed100-color, #F04159);
  transition: all 0.3s ease;
  font-size: 16px;
  right: 0;
  opacity: 0.8;
  left: auto;
  z-index: 1;
}
.destroyBubble .ui.items {
  margin-top: 0em !important;
}
.infostreamitem.out .destroyBubble .ui.label .icon.trash {
  font-size: 14px;
}
.infostreamitem.out .destroyBubble .ui.label:hover,
.filestreamContainer .destroyBubble .ui.label:hover,
.filestreamNodeView .ui.label:hover {
  color: var(--roseRed50-color, #FFA0AD);
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 1;
  background-color: transparent;
}
.infostreamitem.out i.red.icon.trash,
.filestreamContainer i.red.icon.trash {
  color: var(--roseRed100-color, #F04159) !important;
}
.infostreamitem .actionBubble,
.infostreamitem .destroyBubble {
  font-size: 16px;
}
.imageViewer.ui.modal {
  min-height: 80vh;
  display: flex !important;
  flex-direction: column;
}
.imageViewer.ui.modal > .content {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  background-color: var(--ghostWhite-color, #E0E5EC);
  overflow: hidden;
}
.imageViewer.ui.modal > .actions {
  align-self: flex-end;
  width: 100%;
}
.imageViewer .actions .icon {
  margin: 0.4em 0.2em 0.4em 1.6em;
  cursor: pointer;
  color: var(--rhythm-color, #5E6E83);
  transition: color 0.3s ease;
}
.imageViewer .icon:hover {
  color: var(--nightBlue-color, #0C0042);
}
.imageViewer .image img {
  transition: all 0.3s ease;
  max-width: 100%;
  max-height: 70vh;
}
.ui.dimmer .imageViewer .ui.active.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}
.ui.dimmer .imageViewer .ui.active.loader:after {
  border-color: #767676 transparent transparent;
}
.infostreamitem.in .actionBubble {
  background: var(--white-color, #ffffff);
  border: 1px solid var(--light-color, #e6e6e6);
  border-radius: 0px 16px 16px 16px;
  padding: 10px 12px;
  float: left;
}
.infostreamitem.out .actionBubble {
  background: var(--white-color, #ffffff);
  border: 1px solid var(--lavenderGrey, #C0CCDC);
  border-radius: 16px 16px 0px 16px;
  padding: 10px 12px;
  float: right;
  transition: all 0.3s ease;
}
.infostreamitem .item.deleted {
  text-decoration: line-through;
}
.infostreamitem .deleted .fileInfo {
  cursor: default;
}
.infostreamitem .deleted .fileInfo:hover {
  color: var(--nightBlue-color, #0C0042);
  text-shadow: none;
}
.fileInfo,
.locationInfo {
  color: var(--nightBlue-color, #0C0042) !important;
  word-break: break-all;
  cursor: pointer;
}
.fileInfo:hover,
.locationInfo:hover {
  color: var(--shark-color, #23252d);
  text-shadow: 0px 0px 1px var(--shadow-color, rgba(0, 0, 0, 0.1));
}
.authorName {
  font-size: 16px;
  color: var(--rhythm-color, #5E6E83) !important;
  margin-bottom: 4px;
  cursor: default;
}
.fileThumbnail .authorName {
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fileName {
  font-size: 16px;
  color: var(--nightBlue-color, #0C0042);
  margin-top: 6px;
  cursor: default;
}

.fileName p {
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ui.menu > .item:first-child:hover,
.ui.menu > .item:last-child:hover {
  background: none;
}
.infostreamitemUpdated .ui.menu .item .ui.dropdown.activeTimer i.icon {
  color: var(--roseRed100-color, #F04159);
}
.infostreamNode .ui.compact.menu {
  box-shadow: none;
  border: none;
  background: transparent;
}
.infostreamNode .ui.menu .item > i.icon,
.infostreamNode .ui.dropdown,
.infostreamitemUpdated .ui.menu .item i.icon {
  color: var(--rhythm-color, #5E6E83);
  transition: color 0.3s ease;
  margin: 0;
}
.infostreamNode .ui.menu .item > i.icon:hover,
.infostreamNode .ui.dropdown:hover,
.infostreamitemUpdated .ui.menu .item i.icon:hover {
  color: var(--nightBlue-color, #0C0042);
  transition: color 0.3s ease;
}
.infostreamNode .ui.menu .item:before {
  top: 33%;
  height: 33%;
}
#new-messages {
  min-height: 36px;
  margin: auto 0 0 0;
  border-radius: 0;
  position: absolute;
  bottom: 70px;
  background: #ffffffed !important;
  width: calc(100% - 50px);
}
#new-messages span {
  display: inline-block;
  position: relative;
  width: 180px;
}
#new-messages i.icon.chevron.down {
  animation-name: arrow-down;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  position: absolute;
  left: 20px;
  font-size: 10px;
}
#new-messages i.icon.chevron.down.top {
  animation-name: arrow-down;
}
#new-messages i.icon.chevron.down.bottom {
  animation-name: arrow-down-bottom;
}
@keyframes arrow-down {
  0% {
    transform: translateY(-7px);
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(-2px);
    opacity: 0.6;
  }
}
@keyframes arrow-down-bottom {
  0% {
    transform: translateY(-3px);
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(2px);
    opacity: 0.6;
  }
}
.actionBubble img,
.destroyBubble img {
  transition: all 0.3s ease;
}
.actionBubble img:hover {
  transform: scale(0.98);
}
.typingIndicator {
  color: var(--rhythm-color, #5E6E83);
  margin: 10px 64px;
}
.infostreamitem.out .column {
  justify-content: flex-end;
  margin-left: auto;
}
.infostreamitem.in {
  margin: 0 20px;
}
.row.infostreamitemUpdated {
  font-size: 12px;
  color: var(--rhythm-color, #5E6E83);
  clear: both;
}
.infostreamitemUpdated .dropdown {
  margin-right: 1em;
}
.row.infostreamitemUpdated.out {
  text-align: right;
}
.filestreamContainer .destroyBubble {
  background: #fff6f6;
  box-shadow: 0px 0px 0px 1px #e0b4b4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
  color: var(--shadowDark-color, rgba(0, 0, 0, 0.45));
  transition: all 0.3s ease;
}
.filestreamContainer .fileOptions div {
  display: inline;
}
.filestreamNodeView .ui.label {
  background-color: transparent;
  color: var(--roseRed100-color, #F04159);
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  padding-left: 1.4em;
  margin-right: auto;
}
.filestreamContainer .destroyBubble .ui.label {
  background-color: transparent;
  color: var(--roseRed100-color, #F04159);
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  padding: 0;
}
.filestreamContainer input {
  box-shadow: 0px 0px 0px 1px var(--seaGreen100-color, #41B571) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
.filestreamContainer i.green.icon {
  color: var(--seaGreen100-color, #41B571);
}
.filestreamContainer i.green.icon:hover {
  color: var(--seaGreen50-color, #A6F0C5);
}
i.green.circle.tiny.icon {
  padding: 10px;
  font-size: 7px;
  float: right;
}
.filestreamContainer .destroyBubble .ui.label > .icon {
  margin: 0;
  font-size: 14px;
}
.filestreamContainer .filestreamNode .ui.table {
  border: none;
}
.filestreamContainer .filestreamNodeView {
  justify-content: flex-end;
  display: flex;
}
.filestreamContainer .filestreamNodeView span {
  color: var(--neonBlueHover-color, #4854EB);
  margin-top: 1em;
  transition: all 0.2s ease-in-out;
}
.filestreamContainer .filestreamNodeView .icon {
  margin: 0.6em;
  font-size: 1.4em;
  color: var(--rhythm-color, #5E6E83);
  cursor: pointer;
}
.filestreamContainer .filestreamNodeView .icon.active {
  color: var(--neonBlue-color, #5863F8);
}
.galleryItem {
  height: 180px;
  width: auto;
  transition: all 0.2s ease-in-out;
  background-color: var(--ghostWhite-color, #E0E5EC);
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.galleryItem i.icon.fileIcons {
  font-size: 4.4em;
  cursor: pointer;
  margin: 0;
  background-position: center;
}
.galleryItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid var(--light-color, #e6e6e6);
}
.doubling .ui.input {
  margin: 0.2em 0;
  width: 100%;
}
.revealedInfo {
  position: absolute;
  top: 1rem;
  right: 1rem;
  left: 1rem;
  background-color: rgba(242, 242, 242, 0.9);
  border: 1px solid var(--gainsboro-color, #dcdcdc);
  align-items: flex-end;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  display: none;
}
.galleryItem:hover + .revealedInfo,
.revealedInfo:hover,
.destroyBubble .revealedInfo {
  display: flex;
}
.filestreamNode .doubling.grid .column,
.filestreamNode .doubling.grid .column.destroyBubble {
  border-radius: 16px;
  border-top-right-radius: 0px;
}
.filestreamContainer .ui .revealedInfo .fileOptions .icon {
  color: var(--nightBlue-color, #0C0042);
}
.filestreamContainer .ui.grid.galleryItemInfo {
  padding: 0;
  margin: -0.13rem -1rem;
}
.filestreamContainer .destroyBubble .revealedInfo .deleteImage {
  padding: 1em;
}
.galleryItemInfo .column.four {
  margin-right: 0;
  text-align: right;
  padding-left: 0 !important;
}
.galleryItemInfo .column.twelve {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.galleryUpdatedOn {
  color: var(--rhythm-color, #5E6E83);
  font-size: 12px;
  transform: translateY(3px);
  display: inline-block;
}
.four.wide.column .spaceAvatar.ui.avatar.image {
  display: inline-block;
  height: 1.2em;
  max-width: 1.2em;
  min-width: 1.2em;
  border-radius: 500rem;
  text-align: right;
}
.edit.imageName.icon {
  display: inline;
  color: var(--rhythm-color, #5E6E83);
}
.filestreamNode .column .row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filestreamContainer .ui.grid {
  padding: 20px;
}
.filestreamContainer .filesize {
  background: var(--ghostWhite-color, #E0E5EC);
  padding: 0.34em 0.5em;
  border-radius: 0.2em;
  font-size: 12px;
  cursor: default;
}
.filestreamContainer .column .filesize {
  float: right;
  padding: 0.18em 0.5em;
}
.filestreamContainer .ui.table .fileOptions {
  text-align: right;
}
.filestreamContainer .ui .fileOptions .icon {
  margin: 0 10px;
  color: var(--rhythm-color, #5E6E83);
  transition: color 0.3s ease;
  cursor: pointer;
  padding: 1em 0;
  display: inline-table;
}
.filestreamContainer .ui .fileOptions .icon:hover {
  color: var(--nightBlue-color, #0C0042);
  transition: color 0.3s ease;
}
/* --------------------End of Infostream-------------------- */

/* --------------------Infostream Footer-------------------- */

.infostreamFooter,
.filestreamFooter {
  background-color: var(--ghostWhite-color, #E0E5EC);
  border-top: 1px solid var(--gainsboro-color, #dcdcdc);
  display: flex;
}
.infostreamFooter.ui.form textarea,
.infostreamFooter.ui.form textarea:focus {
  background: transparent;
  border: none;
  padding: 1.5em 5em 1em 0.5em;
  width: 100%;
  height: 70px;
}
.infostreamFooter .attachmentIcon,
.filestreamFooter .attachmentIcon {
  background: url('../../assets/icons/attachment.svg') no-repeat;
  cursor: pointer;
  padding: 30px;
  background-size: 40%;
  background-position: center;
  transition: background-color 0.1s ease;
}
.infostreamFooter .attachmentIcon:hover,
.filestreamFooter .attachmentIcon:hover {
  background-color: var(--aliceBlue-color, #F1F5F9);
}
.infostreamFooter .ui.button.sendIcon {
  position: absolute;
  right: 10px;
  transform: translate(-10px, 25%);
}
.pageInfo {
  text-align: center;
  margin: 2em;
}
#company-container .image + .content {
  padding: 0em 0em 0em 1em;
}
.filestreamNode .empty,
.pageInfo .empty {
  color: var(--rhythm-color, #5E6E83);
  text-align: center;
}
/* --------------------End of Infostream Footer-------------------- */

/* --------------------Company-------------------- */

#company-container {
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 62px);
}
#company-container .companyHeader {
  padding: 10px 30px;
  background-color: var(--ghostWhite-color, #E0E5EC);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--ghostWhite-color, #E0E5EC);
  border-bottom: 1px solid var(--gainsboro-color, #dcdcdc);
  margin: 0 !important;
  cursor: default;
  padding-left: 0.4em;
}
#company-container .newToCrypho {
  background-color: var(--ghostWhite-color, #E0E5EC);
  border-top: 1px solid var(--gainsboro-color, #dcdcdc);
  cursor: default;
}
#system-admin-container.adminScene {
  margin: 1rem 1rem;
  overflow: unset;
}
#system-admin-container .buttons-container {
  float: right;
}
#system-admin-container .admin-select {
  margin-bottom: 1.5rem;
  max-width: 40rem;
}
#system-admin-container .two.column.row .column{
  max-width: 40rem;
}
#system-admin-container .two.column.row .card {
  max-width: 18rem;
  min-width: unset;
}
#system-admin-container .ui.mini.circular.image {
  margin-left: 1rem;
}
#system-admin-container .ui.vertically.divided.grid {
  margin-top: 1rem;
}
#system-admin-container .ui.divided.middle.aligned.list{
  position: relative;
}
#system-admin-container .admin-list-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.67);
  z-index: 100;
}
#system-admin-container .list .item,
#company-container .list .item {
  padding: 0.9em 1.2em 0.8em 1em;
  transition: all 0.3s ease;
}
#company-container .list .item .content {
  word-break: break-word;
  display: inline-block;
  padding: 0em 0em 0em 1em;
}
#company-container .list .item div a,
#company-container .newUser,
#company-container p {
  font-size: 16px;
  word-break: break-word;
  font-weight: normal;
  padding: 0.2em 0;
  text-align: left;
}
#company-container .companyHeader .edit {
  color: var(--rhythm-color, #5E6E83);
  transform: translate(0.05em, -0.15em);
  cursor: pointer;
  transition: color 0.3s ease;
}
#company-container .companyHeader i {
  font-size: 14px;
}
#company-container .comment,
#company-container .add {
  color: var(--rhythm-color, #5E6E83);
  font-size: 16px;
  transition: color 0.3s ease;
  margin-right: 1em;
}
#company-container .comment:hover,
#company-container .add:hover {
  color: var(--nightBlue-color, #0C0042);
}
#company-container .companyHeader .ui.input > input {
  font-size: 14px;
}
#company-container .companyHeader i:hover {
  color: var(--nightBlue-color, #0C0042);
}
#company-container .right.floated.content {
  display: -webkit-inline-box;
}
#company-container .ui.divided.middle.aligned.list {
  margin: 0em 0em;
}
#company-container .description {
  color: var(--rhythm-color, #5E6E83);
}
#company-container .unknownUser {
  background-image: url('../../assets/images/unknown.png');
  background-repeat: no-repeat;
  height: 42px;
  width: 42px;
  background-size: 100%;
  display: inline-block;
}
/* --------------------End of Company-------------------- */
/* --------------------Add Contact / Create Group-------------------- */

.fullWidth {
  width: 100% !important;
}
.fullWidth .welcome {
  justify-content: center;
}
.fullWidth .descriptionContainer .ui.cards.welcome > div:nth-of-type(1) {
  background-color: #fcfff5;
}
.fullWidth .descriptionContainer .ui.cards.welcome > div:nth-of-type(2) {
  background-color: #f8ffff;
}
.fullWidth .descriptionContainer .ui.cards.welcome > div:nth-of-type(3) {
  background-color: #fffaf3;
}
.fullWidth .welcome .header {
  font-weight: 500 !important;
  margin-top: 0.8em !important;
}
.fullWidth .welcome .appstore {
  background-image: url('../../assets/images/app-store.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 148px;
  height: 44px;
}
.fullWidth .welcome .googleplay {
  background-image: url('../../assets/images/google-play.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 148px;
  height: 44px;
}
.fullWidth .descriptionContainer .welcome .description {
  margin: 1.5em 0 !important;
  text-align: left;
  font-size: 14px;
}
.fullWidth .descriptionContainer .ui.header {
  font-weight: bold;
  color: var(--neonBlue-color, #5863F8);
}
.fullWidth .descriptionContainer .sub.header {
  margin-bottom: 3em;
  font-size: 16px;
}
.appDownload {
  display: flex;
  justify-content: space-around;
}
.fullWidth .descriptionContainer {
  text-align: center;
  font-size: 14px;
  padding: 5em;
}
.fullWidth .description {
  color: var(--rhythm-color, #5E6E83);
  margin: 1.5em;
}
.fullWidth .descriptionLink {
  color: var(--neonBlue-color, #5863F8);
  font-weight: 400;
}
.fullWidth .invitationList {
  max-width: 700px;
  width: 100%;
  margin: 2em auto 0em;
}
.fullWidth .invitationList .right.floated {
  text-align: right;
}
.fullWidth .spacelistHeader {
  position: sticky;
  top: 62px;
  z-index: 2;
}
.fullWidth .spacelistHeader .buttonContainer span {
  display: inline-block !important;
}
.helpContainer {
  display: inline-flex;
}
.helpContainer i.icon {
  margin-left: 8px;
  transform: translateY(-2px);
}
.helpContainer h3 {
  cursor: help;
}
#scene-container .spacelistHeader {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--ghostWhite-color, #E0E5EC);
  height: 120px;
  border-bottom: 1px solid var(--gainsboro-color, #dcdcdc);
  margin: 0 !important;
  cursor: default;
  padding-left: 0.4em;
  padding-top: 1em;
  width: 380px;
  min-width: 380px;
  transition: min-width 0.3s ease, width 0.3s ease;
}
#scene-container .fullWidth.spacelistHeader {
  height: 66px;
}
#scene-container .spaceSearch {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  transform: translateY(-10px);
  transform: translateY(-8px);
  z-index: 101;
  max-width: 364px;
}
#scene-container .spaceSearch:focus {
  outline: 0;
}
#scene-container .css-2o5izw:hover,
#scene-container .spaceSearch > * {
  border-color: #cccccc;
  outline: 0;
  box-shadow: none;
}
#scene-container .fullWidth .spaceSearch {
  display: none;
}
#scene-container .authenticatedScene .spaceSearch span {
  margin: 0;
}
i.icon.colorFilterIcon {
  margin-left: 1em;
}
/* --------------------AdminScene-------------------- */
.adminScene {
  display: -ms-flexbox;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 62px);
}
.adminScene .ui.table,
.adminScene .ui.table thead th
{
  color: var(--nightBlue-color, #0C0042);
}
.adminScene .ui.table tr td {
  word-break: break-word;
}
.adminScene a {
  cursor: pointer;
  color: var(--neonBlue-color, #5863F8);
}
.adminScene a:hover {
  color: var(--neonBlueHover-color, #4854EB);
}
.adminScene .ui.table thead th {
  min-width: 130px;
}
.adminScene .card h3 {
  padding: 30px 30px 20px 20px;
  margin: 0;
}
.adminContent {
  margin: 20px;
}
.adminScene .ui.list .item .header {
  margin-top: 0.6em;
}
.adminScene .ui.list .item {
  padding: 0.9em 1.2em 0.8em 1em;
  padding: 0 20px;
  min-height: 48px;
  word-break: break-word;
  transition: all 0.3s ease;
}
.adminHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--ghostWhite-color, #E0E5EC);
  border-bottom: 1px solid var(--gainsboro-color, #dcdcdc);
  margin: 0 !important;
  cursor: default;
  padding-left: 0.4em;
  padding-top: 1em;
  transition: min-width 0.3s ease, width 0.3s ease;
  width: 100%;
  height: 66px;
}
.adminContent .emphasized {
  font-weight: bold;
  color: var(--nightBlue-color, #0C0042);
}
.adminHeader > div:first-child {
  margin-left: 1em;
  padding: 0em;
  transform: translateY(-6px);
  z-index: 101;
  min-width: 364px;
}
#scene-container .buttonContainer {
  text-align: right;
  margin: 0;
  padding: 0;
}
#incomingInvitations button.green {
  margin-right: 20px;
}
.ui.circular.button {
  height: 40px;
  width: 40px;
}
.ui.circular.button:hover {
  transition: 0.2s all ease;
}
#scene-container .spacelistHeader span,
#company-container .buttonContainer span {
  color: var(--rhythm-color, #5E6E83);
  margin: 0 20px 0 0;
}
#scene-container .spacelistHeader .header {
  margin: 0;
}
#scene-container .spacelistHeader .sub.header,
#company-container .companyHeader .sub.header {
  color: var(--rhythm-color, #5E6E83);
}
#company-container div.content {
  cursor: default;
}
#company-container .addMember {
  display: -webkit-inline-box;
}
#company-container .addCompanyMember {
  margin-right: 20px;
}
.ui.modals.active .ui.modal > .header {
  background-color: var(--neonBlue-color, #5863F8);
  color: var(--white-color, #ffffff);
  font-weight: normal;
}
.ui.modals.active .ui.modal > .close {
  top: 0.5rem;
  right: 0.5rem;
  color: var(--white-color, #ffffff);
}
.ui.modals.active .ui.input {
  min-width: 280px;
}
input::placeholder {
  font-size: inherit;
}
.ui.form .ui.input input[type='text']:focus,
.ui.form textarea:focus,
.ui.form .ui.input input:focus,
.authenticatedScene .ui.form .rrui__input:focus {
  border-color: var(--gainsboro-color, #dcdcdc);
  outline: 0 !important;
}
/* --------------------End of Add Contact / Create Group-------------------- */

/* --------------------Change color modal-------------------- */

.colorSelect {
  display: inline-table;
  width: 100%;
  background: var(--white-color, #ffffff);
  padding: 2px;
  border-radius: 0.2em;
  box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.05);
  margin: 0;
}
.visible.popup {
  cursor: default;
}
.ui.center.basic.popup {
  z-index: 102;
  max-width: 290px;
}
.colorSelect label {
  transition: all 0.2s ease-in-out;
  display: table-cell;
  cursor: pointer;
  padding: 2em 1em;
  opacity: 0.7;
  width: 5%;
  border: 2px solid white;
}
.colorSelect label.color0 {
  background-color: #ec5f67;
  -webkit-tap-highlight-color: #ec5f67;
}
.colorSelect label.color1 {
  background-color: #f99157;
  -webkit-tap-highlight-color: #f99157;
}
.colorSelect label.color2 {
  background-color: #fac863;
  -webkit-tap-highlight-color: #fac863;
}
.colorSelect label.color3 {
  background-color: #99c794;
  -webkit-tap-highlight-color: #99c794;
}
.colorSelect label.color4 {
  background-color: #5fb3b3;
  -webkit-tap-highlight-color: #5fb3b3;
}
.colorSelect label.color5 {
  background-color: #6699cc;
  -webkit-tap-highlight-color: #6699cc;
}
.colorSelect label.color6 {
  background-color: #c594c5;
  -webkit-tap-highlight-color: #c594c5;
}
.colorSelect label.color7 {
  background-color: #ab7967;
  -webkit-tap-highlight-color: #ab7967;
}
.colorSelect label:first-of-type {
  border-radius: 0.1em 0 0 0.1em;
}
.colorSelect label:last-of-type {
  border-radius: 0 0.1em 0.1em 0;
}
.colorSelect label:hover {
  opacity: 1;
  color: var(--white-color, #ffffff);
  letter-spacing: normal;
}
.colorSelect input {
  display: none;
}
.colorSelect input:checked + label,
.colorSelect .selected {
  width: 60%;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  border: white;
  box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.08);
}
/* --------------------End of Change color modal-------------------- */

/* --------------------File handling modals-------------------- */

.modals.active .mini.destructive.ui.modal > .actions {
  background: none;
  padding: 0rem 1rem;
  border-top: none;
  text-align: right;
}
