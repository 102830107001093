:root {
  --neonBlueLight-color: #DEE0FE;
  --neonBlue-color: #5863F8;
  --neonBlueHover-color: #4854EB;
  --nightBlue-color: #0C0042;
  --ghostWhite-color: #E0E5EC;
  --lavenderGrey: #C0CCDC;
  --rhythm-color: #5E6E83;
  --aliceBlue-color: #F1F5F9;
  --roseRed100-color: #F04159;
  --roseRed50-color: #FFA0AD;
  --roseRedError-color: #C12338;
  --deepOrange100-color: #FE9D47; 
  --deepOrange50-color: #FFCB9C; 
  --deepOrange20-color: #FFE9D7; 
  --deepOrangeWarning-color: #D47927;
  --seaGreen100-color: #41B571;
  --seaGreen50-color: #A6F0C5;

  /* old colors */
  --iceberg-color: #eaf2ff;
  --hawkesBlue-color: #d2daed;
  --marine-color: #1c7c9b;
  --marineHover-color: #208eb1;
  --green-color: #5bc090;
  --greenHover-color: #4eb584;
  --orange-color: #fcbb50;
  --tomato-color: #ea5c42;
  --tomatoHover-color: #da5339;
  --error-color: #9f3a38;
  --white-color: #ffffff;
  --offWhite-color: #fbfbfb;
  --grey95-color: #f2f2f2;
  --grey97-color: #f7f7f7;
  --gainsboro-color: #dcdcdc;
  --light-color: #e6e6e6;
  --darkGrey-color: #777777;
  --offBlack-color: #323440;
  --shark-color: #23252d;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --shadowDark-color: rgba(0, 0, 0, 0.45);
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: local('Roboto-Medium'), local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('woff2'),
    url(./fonts/Roboto-Medium.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto-Regular'), local('Roboto-Regular'), url(./fonts/Roboto-Regular.ttf) format('woff2'),
    url(./fonts/Roboto-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto-Regular'), local('Roboto-Regular'), url(./fonts/Roboto-Regular.ttf) format('woff2'),
    url(./fonts/Roboto-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto-Light'), local('Roboto-Light'), url(./fonts/Roboto-Light.ttf) format('woff2'),
    url(./fonts/Roboto-Light.ttf) format('woff');
}

*:not(.icon) {
  font-family: 'Roboto', sans-serif !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  min-height: 500px;
  color: var(--nightBlue-color, #0C0042);
}

.ui.header {
  font-weight: 500;
  color: var(--nightBlue-color, #0C0042);
}

.ui.warning.message {
  background-color: var(--deepOrange20-color, #FFE9D7);
  color: var(--deepOrangeWarning-color, #D47927);
}

body {
  margin: 0;
  padding: 0;
}

body .ui.popup {
  z-index: 1000;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

#scene-container {
  flex-direction: row;
  flex: 1;
  height: 100%;
  background: var(--white-color, #ffffff);
}

.authenticatedScene {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  -moz-height: auto;
}

.list-container {
  display: flex;
  flex-direction: column;
}

#contact-scene,
#group-scene {
  flex-direction: row;
  display: flex;
  height: calc(100vh - 62px);
  min-height: 500px;
}

.authenticatedScene iframe {
  height: 650px;
  width: 652px;
  border: none;
}

.infostreamContainer,
.filestreamContainer {
  overflow-y: auto;
}

.spaceTabs,
.ui.bottom.attached.segment.active.tab {
  overflow-y: auto;
}

#c-recovery-key {
  font-size: 1.4em;
}

.printOnly {
  display: none;
  margin: 5mm;
}

@page {
  size: auto;
  margin: 5mm;
}

@media print {
  .ui.tiny.modal {
    box-shadow: none;
    font: 12pt Georgia, 'Times New Roman', Times, serif;
    line-height: 1.3;
    font-size: 16pt;
    position: absolute !important;
    top: 60px;
    left: 0;
  }

  .ui.modals.active .ui.modal>.close {
    display: none;
  }

  .noPrint {
    display: none;
  }

  .printOnly {
    display: block;
  }
}

/* --------------------SplashScene-------------------- */
.splash-container {
  height: 100vh;
  padding: 33vh 0;
  background: var(--nightBlue-color, #0C0042);
}

.splash {
  transform-origin: center;
  position: relative;
  display: block;
  margin: auto;
  width: 166px;
  height: 166px;
}

.splash div {
  position: absolute;
  width: 166px;
  height: 166px;
  background-size: contain;
}

.splash #outer-circle {
  animation: rotate 5s infinite linear;
  animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  background-image: url('assets/images/outer-circle.png');
}

.splash #mid-circle {
  animation: rotate 2s infinite linear;
  animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  animation-direction: reverse;
  background-image: url('assets/images/mid-circle.png');
}

.splash #inner-circle {
  animation: rotate 4s infinite linear;
  animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  background-image: url('assets/images/inner-circle.png');
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.splash-container .cryphoLogotype {
  width: 166px;
  height: 30px;
  background-size: contain;
  background-image: url(./assets/images/logotype-white.png);
  background-repeat: no-repeat;
  margin: 2em auto 4em;
}

.splash-container p {
  color: var(--white-color, #ffffff);
  font-size: 16px;
  animation: opacity 6s infinite ease-out;
  text-align: center;
}

.splash-container p span {
  width: 1.25em;
  display: inline-block;
  text-align: left;
}

.splash-container p span::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 1300ms infinite;
  animation-iteration-count: infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@keyframes opacity {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.spaceAvatar.ui.avatar.circular.image {
  width: 42px !important;
  min-width: 42px;
  height: 42px;
  object-fit: cover;
  max-width: fit-content;
  background-color: var(--ghostWhite-color, #E0E5EC);
  border: 1px solid #dcdcdc;
}

/* --------------------LoginScene-------------------- */

.darkContainer {
  flex-direction: row;
  flex: 1;
  height: 100%;
  background-color: var(--nightBlue-color, #0C0042);
  background-image: url('assets/images/illustration.png'), linear-gradient(var(--shark-color, #23252d), transparent);
  background-repeat: no-repeat;
  background-position: 50%, center top;
}

.cardContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardContainer .cryphoLogotype {
  top: 4em;
  right: 4em;
  position: absolute;
  background-image: url('assets/images/logotype-white.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 14em;
  height: 44px;
}

.cardContainer .form {
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 380px;
  width: 466px;
  max-width: 100vw;
  background: var(--white-color, #ffffff);
  border-radius: 1px;
  box-shadow: 0px 2px 21px -4px var(--shadowDark-color, rgba(0, 0, 0, 0.45));
  transition: all 0.1s ease;
  z-index: 1;
}

.card .ui.basic.segment {
  padding: 0;
}

.redHeader {
  background: var(--roseRed100-color, #F04159);
  padding: 20px 30px 30px 30px;
}

.redHeader .header {
  color: var(--white-color, #ffffff);
}

.formHeader {
  background-color: var(--nightBlue-color, #0C0042);
  padding: 0 30px;
}

.cardLogo {
  background-image: url('assets/images/logo.png');
  background-size: 44px;
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  float: right;
  margin: 24px 0;
}

.formHeader .header {
  color: var(--white-color, #ffffff);
  font-size: 36px;
  margin: 28px 0;
}

.formHeader img {
  width: 44px;
  margin: 30px;
}

.card input::-webkit-outer-spin-button,
.card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card input[type='number'] {
  -moz-appearance: textfield;
}

.card .ui.input input::-webkit-input-placeholder {
  color: var(--rhythm-color, #5E6E83);
  opacity: 1;
}

.card .ui.input input::-moz-placeholder {
  color: var(--rhythm-color, #5E6E83);
  opacity: 1;
}

.card .ui.input input:-ms-input-placeholder {
  color: var(--rhythm-color, #5E6E83);
  opacity: 1;
}

.card .ui.input input:-moz-placeholder {
  color: var(--rhythm-color, #5E6E83);
  opacity: 1;
}

.card .description,
.popup .description {
  color: var(--rhythm-color, #5E6E83);
  font-size: 0.92em;
  margin-bottom: 20px;
  font-weight: normal;
}

.card .form .primaryButton,
.modal.active .primaryButton {
  background: var(--neonBlue-color, #5863F8);
  color: var(--white-color, #ffffff);
  border: 1px solid #007e9e;
  border-radius: 3px;
  padding: 1em;
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  transition: all 0.2s ease;
}

.card .form .primaryButton:hover,
.modal.active .primaryButton:hover,
.newVersion .primaryButton:hover {
  background: var(--neonBlueHover-color, #4854EB);
  color: var(--white-color, #ffffff);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.ui.negative.button {
  background: var(--roseRed100-color, #F04159);
  color: var(--white-color, #ffffff);
  border: 1px solid var(--roseRed100-color, #F04159);
  border-radius: 3px;
  padding: 0.8em 1em;
  font-size: 14px;
  font-weight: normal;
  transition: all 0.2s ease;
  margin: 4px 0;
}

.ui.negative.button:hover {
  background: var(--roseRed50-color, #FFA0AD);
  color: var(--white-color, #ffffff);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.05);
}

#invitations-scene .invitationToken .copyButton,
.authenticatedScene .settingsButton.button,
.ui.modals.active .ui.modal .actions>.button,
.card .securityTokenButton,
.companyHeader .companyHeaderButton,
.popup .primaryButton {
  background: var(--nightBlue-color, #0C0042);
  color: var(--white-color, #ffffff);
  border: 1px solid var(--nightBlue-color, #0C0042);
  border-radius: 3px;
  padding: 0.8em 1em;
  font-size: 14px;
  font-weight: normal;
  transition: all 0.2s ease;
}

#invitations-scene .invitationToken .copyButton .icon {
  background-color: rgb(35, 35, 37);
  box-shadow: -1px 0px 0px 0px #fff3 inset;
}

#invitations-scene .invitationToken .copyButton:hover {
  opacity: 0.94;
}

.ui.modals.active .ui.modal .actions>.button {
  margin: 22px 0;
}

.authenticatedScene .settingsButton.button {
  margin: 8px 0 22px;
}

.settingsButton.saveProfile.button {
  margin-left: 1rem;
}

.settingsButton.editMobile.button {
  margin-left: 1rem;
}
.settingsButton.cancelMobile.button {
  margin-left: 1rem;
}

.bankIdSection.ui.items {
  margin: 3em 0 5em 0;
}

.bankIdSection.ui.items a.item.disabled {
  opacity: 0.7;
}

.bankIdSection.ui.items a.item .content .header,
.accountOptions .content h3 {
  font-size: 18px;
  font-weight: normal;
}

.bankIdSection.ui.items a.item:hover .content .header {
  color: var(--neonBlue-color, #5863F8);
}

.bankIdSection.ui.items a.item.disabled .content .header,
.bankIdSection.ui.items a.item.disabled:hover .content .header {
  color: rgba(0, 0, 0, 0.5);
}

.bankIdSection.ui.divided.items>.item {
  min-height: 92px;
}

.bankIdSection.ui.items .item .image {
  width: 102px;
  border-radius: 4px;
  height: 64px;
  transition: all 0.2s ease-in-out;
}

.bankID {
  background-image: url('assets/images/bankid.svg');
  background-repeat: no-repeat;
}

.bankIDmobile {
  background-image: url('assets/images/bankid_mobil.svg');
  background-repeat: no-repeat;
}

.fingerprint {
  background-image: url('assets/images/fingerprint.svg');
  background-repeat: no-repeat;
}

.fingerprint-token {
  font-family: monospace !important;
}

#verifiedPopup.ui.popup>.header+.content {
  padding-bottom: 0.5em;
}

#verifiedPopup.popup .symbol {
  display: inline-block;
  width: 42px;
  min-width: 42px;
  margin-right: 10px;
  height: 26px;
  transform: translateY(-4px);
  border-radius: 2px;
}

#verifiedPopup.popup .content .item {
  margin: 6px 0 0 0 !important;
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-bottom: 1px solid var(--lavenderGrey, #C0CCDC);
}

.ui.items>.item>.content>.description {
  margin-bottom: 0;
  color: var(--nightBlue-color, #0C0042);
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"] {
  border: 1px solid var(--lavenderGrey, #C0CCDC);
  color: var(--nightBlue-color, #0C0042);
}

#verifiedPopup.popup .content {
  vertical-align: -webkit-baseline-middle;
}

#unverifiedPopup.ui.popup>.header {
  opacity: 0.5;
  font-weight: 400;
}

.card .securityTokenButton {
  margin-bottom: 20px;
}

.authenticatedScene .settingsButton.button:hover,
.authenticatedScene .settingsButton.button:focus,
.authenticatedScene .settingsButton.button:active,
.ui.modals.active .ui.modal .actions>.button:hover,
.ui.modals.active .ui.modal .actions>.button:focus,
.ui.modals.active .ui.modal .actions>.button:active,
.card .securityTokenButton:hover,
.card .securityTokenButton:focus,
.card .securityTokenButton:active,
.companyHeader .companyHeaderButton:hover,
.companyHeader .companyHeaderButton:focus,
.companyHeader .companyHeaderButton:active .popup .primaryButton:hover,
.popup .primaryButton:focus,
.popup .primaryButton:active,
.popup .primaryButton:hover {
  background: var(--shark-color, #23252d);
  color: var(--white-color, #ffffff);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.card .securityTokenButton {
  float: right;
}

.fullWidth .buttonContainer .ui.button.spacelistHeaderButton {
  margin-right: 20px;
}

.authenticatedScene .ui.button.unlinkDevice {
  margin-left: 20px;
}

#scene-container #company-container .item .ui.button {
  background-color: var(--white-color, #ffffff);
}

#scene-container .ui.red.button {
  background-color: var(--white-color, #ffffff);
  color: var(--roseRed100-color, #F04159);
}

#scene-container .ui.red.button:hover {
  color: var(--roseRed50-color, #FFA0AD);
}

#scene-container .ui.green.button {
  color: var(--seaGreen100-color, #41B571);
  background-color: var(--white-color, #ffffff);
}

#scene-container .ui.green.button:hover {
  color: var(--seaGreen50-color, #A6F0C5);
}

#scene-container .ui.blue.button {
  background-color: var(--neonBlue-color, #5863F8);
  color: var(--white-color, #ffffff);
}

#scene-container .ui.blue.button:hover {
  background-color: var(--neonBlueHover-color, #4854EB);
}

#scene-container .inlineInput {
  display: inline-block;
}

.renameCompany {
  display: inline-block;
}

.form .inlineInput .react-tel-input input {
  padding-left: 44px;
}

.card .form .field label {
  font-weight: normal;
  font-size: 0.88em;
  margin: 10px 0;
}

.card .ui.checkbox input:checked~.box:after,
.card .ui.checkbox input:checked~label:after {
  color: var(--neonBlue-color, #5863F8);
  font-size: 12px;
}

.error.field .ui.label.errorLabel {
  color: var(--roseRedError-color, #C12338);
  background-color: var(--white-color, #ffffff);
  font-weight: normal;
  padding: 0;
  margin-top: 6px;
  line-height: 14px;
}

.card .ui.form .required.fields:not(.grouped)>.field>label:after,
.card .ui.form .required.fields.grouped>label:after,
.card .ui.form .required.field>label:after,
.ui.form .required.field>label:after {
  display: none;
}

.form .field.inlineInput label:not(.form.invite .field.inlineInput label) {
  display: none;
}

#profile-picture>div.ui.visible.content>input.avatarChange {
  display: none;
}

.invite {
  margin: 20px 0;
}

.ui.form .PhoneInputCountryIconUnicode {
  font-size: 2rem;
}

.ui.list .list > .item .header, .ui.list > .item .header {
  color: var(--nightBlue-color, #0C0042);
}

.card .form .refresh {
  color: var(--seaGreen100-color, #41B571);
  margin: 0.25em 0em 0em 0em;
  float: right;
  cursor: pointer;
}

.examplePassphrase {
  display: inline;
  margin-right: 10px;
}

.examplePassphrase+span {
  font-family: monospace !important;
}

.whiteTextLink {
  color: var(--white-color, #ffffff);
  margin: 10px;
  opacity: 0.8;
  transition: all 0.2s ease;
  text-shadow: 1px 1px var(--shadowDark-color, rgba(0, 0, 0, 0.45));
}

.whiteTextLink:hover {
  opacity: 1;
  color: var(--white-color, #ffffff);
  transition: all 0.2s ease;
}

.blueTextLink {
  color: var(--neonBlue-color, #5863F8);
  margin: 10px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: block;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.documentation {
  color: var(--neonBlue-color, #5863F8);
  margin: 10px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: block;
  margin: 0;
  text-align: right;
}

.top20 {
  margin-top: 20px;
}

.top10 {
  margin-top: 10px;
}

.card .ui.checkbox input.hidden+label {
  pointer-events: none;
}

.card .ui.checkbox label:before {
  pointer-events: auto;
}

.card .ui.checkbox input.hidden+label a {
  pointer-events: auto;
}

#termsCheckbox {
  z-index: 1;
}

.termsLink,
.welcome .content .blueLink {
  color: var(--neonBlue-color, #5863F8) !important;
}

.blueTextLink:hover,
.documentation:hover,
.termsLink:hover,
.welcome .content .blueLink:hover {
  color: var(--neonBlue-color, #5863F8);
  opacity: 1;
}

.ui.input input[type='email']:read-only {
  color: var(--drakGrey-color, #777777);
  cursor: not-allowed;
}

.greyTextLink {
  display: block;
  margin: 0;
  margin-bottom: 4px;
  text-align: right;
  color: var(--drakGrey-color, #777777);
  opacity: 0.8;
  transition: all 0.2s ease;
  cursor: pointer;
}

.greyTextLink:hover {
  color: var(--drakGrey-color, #777777);
  opacity: 1;
  transition: all 0.2s ease;
}

.changeUser {
  float: right;
  color: var(--drakGrey-color, #777777);
  opacity: 0.8;
  cursor: pointer;
}

.changeUser:hover {
  color: var(--drakGrey-color, #777777);
  opacity: 1;
}

.card .form .ui.action.input .eye.icon,
.changePassword .eye.icon {
  position: absolute;
  right: 0.6em;
  top: 30%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.eye.icon:hover {
  opacity: 0.65 !important;
  transition: all 0.3s ease;
}

input[type='text']+.icon.eye:before {
  content: '\f070';
}

.card .ui.action.input:not([class*='left action'])>input,
.changePassword .ui.action.input:not([class*='left action'])>input {
  padding-right: 32px;
}

.card .ui.action.input:not([class*='left action'])>input,
.changePassword .ui.action.input:not([class*='left action'])>input {
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
  border-right-color: var(--gainsboro-color, #dcdcdc) !important;
}

.card .ui.error.action.input:not([class*='left action'])>input,
.changePassword .ui.error.action.input:not([class*='left action'])>input {
  border-right-color: rgb(224, 180, 180) !important;
}

/* --------------------End of LoginScene-------------------- */

/* --------------------TopBar-------------------- */
#topBar {
  width: 100%;
  background-color: var(--nightBlue-color, #0C0042);
  position: sticky;
  top: 0;
  z-index: 102;
}

#topBar .item {
  color: #fff;
}

#topBar .item>* {
  transition: all 0.5s ease-in;
}

#topBar .ui.secondary.menu .centeredMenu .active.item {
  background-color: var(--shadowDark-color, rgba(0, 0, 0, 0.45));
  box-shadow: 0px 0px 1px var(--rhythm-color, #5E6E83);
}

#myAccount .item .label {
  margin-left: 1em;
}

#myAccount img {
  margin-left: 1em;
  border: 1px solid #ffffffbd;
}

#myAccount .menu {
  left: auto;
  right: 0;
}

.ui.menu #myAccount.ui.dropdown .menu>.item:not(:last-of-type):not(.menuBackground):not(.menuBackground:hover),
#myAccount.ui.dropdown .menu>.header {
  color: var(--nightBlue-color, #0C0042) !important;
}

#myAccount.ui.dropdown .menu>.item:last-of-type {
  color: var(--roseRed100-color, #F04159) !important;
}

#myAccount.ui.dropdown .menu>.item:last-of-type:hover {
  color: var(--roseRed50-color, #FFA0AD) !important;
  background: var(--white-color, #ffffff) !important;
}

.ui.dropdown .menu>.divider,
.ui.dropdown.activeTimer>.left.menu .divider {
  margin: 0 !important;
}

#myAccount .menuBackground {
  background-color: var(--ghostWhite-color, #E0E5EC) !important;
  border-radius: 0;
  color: var(--rhythm-color, #5E6E83) !important;
}

#myAccount .menuBackground:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

#topBar #myAccount .item span {
  display: block;
}

#topBar .item .label {
  background: var(--neonBlue-color, #5863F8);
}

#topBar #myAccount.item .label {
  margin-left: 1em;
}

#topBar .floating.label {
  font-size: 5px;
  top: 1em;
  left: 70%;
  display: none;
}

#topBar .centeredMenu {
  margin: auto !important;
}

#topBar .right.item {
  margin-left: unset !important;
}

.topLogo {
  background-image: url('assets/images/logo-color.png');
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100%;
}

.topLogotype {
  background-image: url('assets/images/logotype-white.png');
  background-repeat: no-repeat;
  height: 40px;
  width: 100px;
  background-size: 100%;
  background-position: center;
  margin-left: 10px;
}

/* --------------------End of TopBar-------------------- */
#scene-container.away>*:not(#away-container) {
  filter: blur(5px);
  transition: all 0.5s ease;
}

#scene-container.away #away-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: #a8aab4;
  text-shadow: 0px 1px 5px var(--white-color, #ffffff);
}

.redirect.ui.list {
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--ghostWhite-color, #E0E5EC);
  position: absolute;
  top: 0;
  z-index: 103;
  padding: 1em;
  margin: 0;
  box-shadow: 0px 1px 2px var(--shadow-color, rgba(0, 0, 0, 0.1));
}

.redirect.ui.list .item {
  display: inline-flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
}

.redirect .item .content {
  margin: 0 10px;
  font-weight: 500;
}

.redirect .item button.primary {
  background: #1c7d9b;
  margin-left: auto;
}

.redirect .item button.primary:hover {
  background: #208eb1;
}

.dropzone:focus {
  outline: none;
}

.infostreamitem .image.thumbnail {
  width: 160px;
  height: 160px;
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;
}

/* Notifications */
.notification-container {
  margin-top: 60px;
}

.notification {
  background-color: #2e303c;
}

.notification .notification-message a {
  color: #98c4fb;
  text-decoration: underline;
}

.notification-info:before {
  color: #2f96b4;
}

.notification-warning:before {
  color: #f89406;
}

.notification-error:before {
  color: #bd362f;
}

.notification-success:before {
  color: #51a351;
}

/* New desktop version */
#scene-container .newVersion {
  position: absolute;
  top: 62px;
  margin: 0;
  border-radius: 0;
  background: #caf3c5;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 0.4em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
  width: 100%;
  z-index: 102;
  opacity: 0.95;
  transition: all 0.3s ease;
}

.newVersion .primaryButton {
  background: var(--neonBlue-color, #5863F8);
  color: var(--white-color, #ffffff);
  border: 1px solid #007e9e;
  border-radius: 3px;
  padding: 1em;
  margin-left: 20px;
  font-size: 14px;
  font-weight: normal;
  transition: all 0.2s ease;
}

/* --------------------AccountOptions-------------------- */
.accountOptions {
  padding: 4em 2em;
  max-width: 700px;
  margin: 0 auto;
  font-size: 16px;
}

.accountOptions .message {
  font-size: 14px;
}

.accountOptions .ui.list .item .description,
.bankIdSection.ui.items>.item>.content p,
.description p {
  color: var(--rhythm-color, #5E6E83);
  font-size: 14px;
}

.changePassword .refresh {
  margin: 0.25em 0em 0em 0em;
  float: right;
  cursor: pointer;
  color: var(--seaGreen100-color, #41B571);
}

.changePassword {
  width: 334px;
}

.changePasswordExample {
  margin: 2em 0;
}

.changePassword span {
  font-family: monospace !important;
}

.accountOptions .ui.form input,
.PhoneInput,
.invite .ui.form .field .ui.input {
  max-width: 334px;
}

.accountOptions .avatar {
  margin-bottom: 2em;
  cursor: pointer;
}

.accountOptions .deviceModel,
.accountOptions .emailListItem {
  line-height: 40px;
}

.accountOptions .ui.items .item.emailListItem .content {
  margin-right: 1.4em;
  flex: none;
}

.accountOptions .emailListItem .description {
  text-transform: uppercase;
  font-size: 0.76em;
  letter-spacing: 0.2em;
  color: var(--rhythm-color, #5E6E83);
  font-weight: bold;
}

.accountOptions .ui.avatar.image {
  width: 120px !important;
  min-width: 120px;
  height: 120px;
  object-fit: cover;
  max-width: fit-content;
  border: 1px solid var(--gainsboro-color, #dcdcdc);
}

.accountOptions .changeAvatar {
  width: 120px !important;
  min-width: 120px;
  height: 120px;
  background-color: var(--ghostWhite-color, #E0E5EC);
  border: 1px solid var(--gainsboro-color, #dcdcdc);
  border-radius: 500rem;
  text-align: center;
  line-height: 90px;
  color: var(--rhythm-color, #5E6E83);
}

.accountOptions .changeAvatar .icon {
  display: block;
  text-align: center;
  margin: 0 auto;
  line-height: 90px;
  font-size: 2em;
}

.accountOptions .addDeviceQR {
  margin: 0 auto;
}

.accountOptions .deviceModel,
.accountOptions .emailListItem {
  line-height: 40px;
}

.accountOptions .ui.form .rrui__input,
.cardContainer .ui.form .rrui__input {
  height: 38px;
}

.accountOptions .ui.form .required.fields:not(.grouped)>.field>label:after,
.accountOptions .ui.form .required.fields.grouped>label:after,
.accountOptions .ui.form .required.field>label:after,
.accountOptions .ui.form .required.fields:not(.grouped)>.field>.checkbox:after,
.accountOptions .ui.form .required.field>.checkbox:after {
  display: none;
}

.accountOptions .blueTextLink {
  display: inline;
  cursor: pointer;
}

.accountOptions .ui.header {
  color: var(--neonBlue-color, #5863F8);
}

.accountOptions .ui.header.invitationType {
  margin: 0;
}

.accountOptions #incomingInvitations {
  margin-bottom: 5em;
}

#invitations-scene .copyLink {
  line-height: 1.8;
  word-break: break-all;
}

#invitations-scene .invitationToken {
  margin-bottom: 4em !important;
}

.accountOptions #outgoingInvitations .content,
.accountOptions #incomingInvitations .content {
  display: inline-block;
  min-height: 40px;
}

.accountOptions #outgoingInvitations .content .header,
.accountOptions #incomingInvitations .content .header {
  margin-bottom: 10px;
}

.accountOptions #outgoingInvitations .right .ui.button,
.accountOptions #incomingInvitations .right .ui.button {
  transform: translateY(-12px);
}

.cardContainer .ui.form .ui.header {
  margin: 10px 0;
}

.rrui__select__button {
  border: none;
}

/* --------------------PageNotFound-------------------- */

.notFound {
  padding: 100px 0;
  text-align: center;
}

.cryphoLogo {
  background-image: url('assets/images/logo-color.png');
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* --------------------Video/Audio Call Full width-------------------- */
#wrtc {
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  min-width: 290px;
  min-height: 220px;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
}

#wrtc:fullscreen #remote-video {
  width: 100% !important;
  max-height: 100% !important;
}

#wrtc:fullscreen #local-video {
  width: 300px;
}

#wrtc video {
  transition: all 0.3s ease;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#remote-video {
  width: auto;
  max-width: 100%;
  max-height: 48vh;
  border: transparent;
  background-color: var(--nightBlue-color, #0C0042);
  flex: 1;
}

#local-video {
  position: absolute;
  width: 140px;
  right: 0;
  margin: 46px 10px;
  z-index: 2;
  border: 1px solid var(--white-color, #ffffff);
  background-color: var(--white-color, #ffffff);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

video.mirrored {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

#scene-container #audio-call {
  margin-left: auto;
}

#scene-container #video-call {
  margin-left: 20px;
}

.callControls {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 3;
}

.callSettings {
  z-index: 10;
  margin: 10px 10px 10px auto;
  color: var(--white-color, #ffffff);
}

.callSettings .ui.dropdown>.dropdown.icon:before {
  margin-right: 12px !important;
}

.inlineName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  margin-right: 0;
  max-width: 400px;
  margin: 0 auto;
}

.callControls .button {
  margin: 0 16px;
}

.screenSize.icon {
  top: 0;
  position: absolute;
  z-index: 6;
  font-size: 18px;
  color: var(--white-color, #ffffff);
  margin: 10px;
}

.ui.circular.button>.tv.icon {
  width: 1.2em;
}

.callHeader {
  position: absolute;
  top: 0px;
  padding: 10px;
  color: var(--white-color, #ffffff);
  background: var(--nightBlue-color, #0C0042);
  z-index: 4;
  width: 100%;
  text-align: center;
}

#scene-container .callControls .ui.red.button {
  color: var(--white-color, #ffffff);
  background-color: var(--roseRed100-color, #F04159);
  transform: rotate(-135deg);
}

#scene-container .callControls .ui.red.button:hover {
  background-color: var(--roseRed50-color, #FFA0AD);
}

@media screen {
  .ui.modals.active #handleCall.ui.modal .actions {
    padding: 0 !important;
  }
}

.ui.modals.active #handleCall.ui.modal .actions>.button> {
  margin: 0 10px;
}

.ui.modals.active #handleCall.ui.modal .actions>.ui.button.acceptCall,
.ui.modals.active #handleCall.ui.modal .actions>.ui.button.acceptCall:hover {
  border: 1px solid #21ba45;
  background-color: #21ba45;
  margin: 1rem 0.75em !important;
}

.ui.modals.active #handleCall.ui.modal .actions>.ui.button.rejectCall,
.ui.modals.active #handleCall.ui.modal .actions>.ui.button.rejectCall:hover {
  background-color: #db2828;
  border: 1px solid #db2828;
  color: #fff;
  text-shadow: none;
  background-image: none;
  margin: 0 0.75em !important;
}

#handleCall p {
  display: inline;
  margin-left: 10px;
}

.pulse {
  animation: pulse 2s infinite;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 50%;
  color: grey;
  background-position: 50%;
  box-shadow: 0 0 0 rgb(76, 175, 80);
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

/*------- Conversation Status -----*/
.statusMessagePopup {
  white-space: pre-wrap;
  overflow-y: auto;
  max-height: calc(100vh - 240px);
}

.statusMessage {
  color: var(--rhythm-color, #5E6E83);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 500px;
  max-width: 70%;
  transition: all 0.3s ease;
}

.statusMessage:hover,
.spaceHeader .sub.header.description:hover {
  color: var(--nightBlue-color, #0C0042);
}

.spaceHeader .expiryHeader {
  display: inline;
  cursor: pointer;
  margin: 0 10px 0px 0px;
  color: var(--rhythm-color, #5E6E83);
  opacity: 0.5;
}

/* --------------------@media adaptations-------------------- */
@media only screen and (max-width: 1500px) {

  .ui[class*='six column'].doubling.grid>.row>.column,
  .ui[class*='six column'].doubling.grid>.column:not(.row),
  .ui.grid>[class*='six column'].doubling.row.row>.column {
    width: 33.33333333% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .filestreamNode .ui.grid .eight.wide.column {
    width: auto !important;
  }

  /* .filestreamContainer .filestreamNode .ui.grid > * {
    padding-left: 0em;
    padding-right: 0em;
  } */
  #topBar .accountOwner {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 500px;
    display: inline-block;
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@media only screen and (max-width: 1200px) {
  .spaceHeader .ui.header span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }

  .spaceList,
  .invitationList,
  #scene-container .spacelistHeader {
    width: 240px;
    min-width: 240px;
    transition: min-width 0.3s ease, width 0.3s ease;
  }

  #topBar .accountOwner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    transform: translateY(5px);
  }

  #scene-container .spaceList .header {
    max-width: 130px;
  }

  .invitationList .ui.grid {
    margin-right: 0;
  }

  .list-container .invitationList .ui.grid [class*='right floated'].column {
    padding: 0;
  }

  .list-container .invitationList button.ui.icon.button {
    margin: 0;
    padding: 0;
    width: 28px;
  }

  .spaceList .item .image {
    width: 3em;
    transition: min-width 0.3s ease, width 0.3s ease;
  }

  .helpContainer h3.ui.header {
    font-size: 1.2em;
  }

  i.icon.colorFilterIcon {
    margin-left: 0.6em;
  }

  #scene-container .spacelistHeader span,
  #company-container .buttonContainer span,
  #company-container .addMember .description {
    display: none;
  }

  #scene-container .ui.grid.spacelistHeader>[class*='six wide'].column {
    width: 25% !important;
  }

  #scene-container .ui.grid.spacelistHeader>[class*='ten wide'].column {
    width: 75% !important;
  }

  #company-container .spacelistHeader .ten.wide.column {
    width: 75% !important;
  }

  #scene-container .spaceSearch span {
    display: table-cell;
    margin: 0;
  }
}

@media screen and (max-width: 1113px) {
  .spaceHeader .ui.header span {
    max-width: 480px;
  }

  .accountOptions .spaceTabs .ui.tabular.menu .item {
    padding: 0.92857143em 0.8em;
    transition: all 0.2s ease-in-out;
  }
}

@media only screen and (max-width: 1000px) {
  .spaceHeader .ui.header span {
    max-width: 322px;
  }

  .ui.negative.button.floated {
    float: none;
  }

  #topBar .accountOwner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    display: inline-block;
    transform: translateY(5px);
  }

  .ui[class*='six column'].doubling.grid>.row>.column,
  .ui[class*='six column'].doubling.grid>.column:not(.row),
  .ui.grid>[class*='six column'].doubling.row.row>.column {
    width: 50% !important;
  }
}

@media only screen and (max-width: 818px) {
  .spaceHeader .ui.header span {
    max-width: 280px;
  }

  .invitationForm {
    flex-direction: column;
  }

  #scene-container .spaceList .header {
    font-size: 14px;
  }

  #topBar .topLogotype {
    display: none;
  }

  #topBar .accountOwner {
    display: none;
  }

  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr,
  .ui.table:not(.unstackable) tr>th,
  .ui.table:not(.unstackable) tr>td {
    width: auto !important;
    display: block !important;
    transition: all 0.3s ease;
  }

  .ui.table:not(.unstackable) tr>th,
  .ui.table:not(.unstackable) tr>td {
    background: none;
    border: none !important;
    padding: 0.25em 0.75em !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ui.table:not(.unstackable) tr {
    padding-top: 1em;
    padding-bottom: 1em;
    -webkit-box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset !important;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset !important;
  }

  .ui.table:not(.unstackable) tr.destroyBubble {
    box-shadow: 0px 0px 0px 1px #e0b4b4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  }

  .filestreamContainer .ui.table .fileOptions {
    text-align: left;
    transition: all 0.3s ease;
  }

  .single.line.right.aligned.two.wide {
    margin-top: 12px;
    margin-right: 12px;
    transition: all 0.3s ease;
    text-align: left;
  }

  .spaceAvatar.ui.avatar.circular.image {
    width: 34px !important;
    min-width: 34px;
    height: 34px;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .spaceHeader .statusMessage {
    width: 400px;
  }

  .filestreamContainer .column .filesize {
    display: none;
  }

  .galleryItem {
    height: 120px;
  }
}

@media screen and (max-width: 786px) {
  .spaceHeader .ui.header span {
    max-width: 220px;
  }

  .bankIdSection.ui.items:not(.unstackable)>.item>.image {
    margin: 0em 0 20px 0;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .ui.container {
    width: auto !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }

  .infostreamContainer i.icon.fileIcons,
  i.icon.locationIcon {
    margin: 0em 0.2em 0em 0em;
  }

  .ui.items:not(.unstackable)>.item {
    margin: 0em !important;
  }

  .ui.items:not(.unstackable)>.item {
    flex-direction: row;
  }

  .ui.items:not(.unstackable)>.item>.image+.content {
    padding: 0em 0em 0em 1em !important;
  }
}

@media screen and (max-width: 717px) {
  .spaceHeader .ui.header span {
    max-width: 182px;
  }

  .spaceHeader .expiryHeader span {
    display: none;
  }

  #contact-scene .spaceHeader .sub.header.description {
    display: none;
  }

  .accountOptions .spaceTabs .ui.menu {
    display: block;
  }

  .accountOptions iframe {
    width: 350px !important;
  }

  .spaceContainer .ui.tabular.menu .active.item {
    color: var(--nightBlue-color, #0C0042) !important;
  }

  .accountOptions .spaceTabs .ui.tabular.menu .active.item {
    border: 1px solid var(--gainsboro-color, #dcdcdc);
    transition: all 0.2s ease-in-out;
  }

  .accountOptions .ui.tabular.menu {
    border-bottom: none;
  }

  .accountOptions .ui.attached:not(.message)+.ui.attached.segment:not(.top) {
    border: 1px solid var(--gainsboro-color, #dcdcdc);
  }

  .spaceHeader .statusMessage {
    width: 300px;
  }

  .filestreamContainer .ui .fileOptions .icon {
    margin: 0 6px;
  }
}

@media only screen and (max-width: 640px) {
  .card {
    transform: scale(0.95);
  }

  .darkContainer,
  .cardContainer {
    min-height: 100vh;
    height: auto;
  }

  .spaceHeader .ui.header span {
    max-width: 130px;
  }

  .spaceHeader .verifiedIcon {
    display: none;
  }

  #topBar .item .label {
    display: none;
  }

  #topBar .ui.menu .centeredMenu .item>i.icon {
    margin: 0;
  }

  #topBar #myAccount .item .label {
    display: inline-block;
  }

  #topBar .item span {
    display: none;
  }

  #topBar .floating.label {
    display: block;
  }

  .accountOptions .ui.avatar.image,
  .accountOptions .changeAvatar {
    width: 110px !important;
    min-width: 110px;
    height: 110px;
    font-size: 0.6em !important;
  }

  #topBar .accountOptions .ui.reveal>.content.changeAvatar {
    font-size: 0.6em !important;
  }

  .infostreamContainer i.icon.fileIcons,
  i.icon.locationIcon {
    font-size: 2em;
  }

  .spaceHeader .statusMessage {
    width: 200px;
  }

  #scene-container .spaceList .header {
    max-width: 80px;
  }

  #scene-container .spaceList .companyName {
    max-width: 115px;
  }

  .infostreamitem.in,
  .infostreamitem.out {
    margin: 0 0px;
  }

  #contact-scene .spaceHeader {
    padding: 11px 6px 10px 0px;
  }

  #group-scene .spaceHeader {
    padding: 14px 6px 8px 0px;
  }

  .spacelistHeader>[class*='ten wide'].column {
    padding-left: 0 !important;
  }

  .PhoneInputInput {
    min-width: unset;
  }

  .spaceList,
  .invitationList,
  #scene-container .spacelistHeader {
    width: 180px;
    min-width: 180px;
    transition: min-width 0.3s ease, width 0.3s ease;
  }

  .galleryItem {
    height: 80px;
  }

  #scene-container .helpContainer .ui.header,
  .invitationList,
  .cardContainer .whiteTextLink {
    display: none;
  }

  .ui.grid>.infostreamitem.in>[class*='fourteen wide'].column {
    width: 84% !important;
  }

  .infostreamNode .wide.column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .invitationToken .ui.grid>.row {
    flex-direction: column !important;
  }

  .invitationToken .ui.grid>.row>.column {
    width: 100% !important;
  }

  #invitations-scene .invitationToken .copyButton {
    margin-top: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .darkContainer {
    background-image: unset;
  }

  .ui.tabular.menu .item {
    padding: 0.9em 0.8em;
  }

  .spaceList .ui.circular.label {
    display: block;
    font-size: 5px;
    height: 5px;
    background-color: var(--neonBlue-color, #5863F8);
    position: absolute;
    z-index: 100;
    top: 1em;
    left: 70%;
  }

  #scene-container .spacelistHeader {
    padding: 15px 6px 10px 0px;
    height: 66px;
  }

  #scene-container .spaceList .badge {
    display: none;
  }

  .cardLogo {
    margin: 10px 0;
  }

  .card {
    min-width: 320px;
  }

  #space-drawer {
    top: 140px;
  }

  .card .ui.button,
  .card .ui.input input {
    padding: 0.8em;
    font-size: 12px;
  }

  .card .formHeader h1.ui.header {
    font-size: 1.8rem;
    margin: 15px 0;
  }

  .spaceContainer {
    padding: 4px;
  }

  .spaceList,
  #scene-container .spacelistHeader {
    width: 78px;
    min-width: 78px;
  }

  .spaceList .spaceColor {
    width: 6px;
  }

  #scene-container .badge {
    padding: 0em 0.2em 0.7em 0em;
    transform: scale(0.8);
  }

  .spaceHeader .ui.header span {
    max-width: 118px;
  }

  .infostreamContainer .spaceAvatar.ui.avatar.circular.image {
    width: 28px !important;
    min-width: 28px;
    height: 28px;
  }

  .ui.grid>.infostreamitem.out>[class*='fourteen wide'].column {
    width: 100% !important;
  }

  .infostreamitem .image.thumbnail {
    max-width: 120px;
  }

  .fileName p {
    max-width: 120px;
  }

  .infostreamFooter .attachmentIcon,
  .filestreamFooter .attachmentIcon {
    padding: 30px 24px;
    margin: 0px;
  }

  #scene-container .spaceList .content,
  #scene-container .unverifiedIcon,
  .cardContainer .cryphoLogotype,
  .spaceHeader>.bell.slash.icon,
  #scene-container .spaceSearch,
  #scene-container .ui.grid.spacelistHeader>[class*='ten wide'].column {
    display: none;
  }

  #contact-scene .spaceHeader .sub.header.description {
    display: none;
  }

  #scene-container .ui.grid.spacelistHeader>[class*='six wide'].column {
    width: 100% !important;
    text-align: center;
  }

  .companyHeader .ui.header {
    width: 100vw;
  }

  .groupMembersTable .ui.header>img+.content,
  .ui.header>.image+.content {
    width: 90px;
  }

  .ui.modals.active .ui.input {
    min-width: 260px;
  }

  .changePassword {
    width: 260px;
  }

  .accountOptions .ui.avatar.image,
  .accountOptions .changeAvatar {
    width: 70px !important;
    min-width: 70px;
    height: 70px;
    line-height: normal;
  }

  .accountOptions .changeAvatar .icon {
    line-height: normal;
    margin-top: 8px;
  }
}