.header {
    display: flex;
}

.actions {
    display: flex;
    padding: 1.875rem 1.875rem 1.25rem 1.25rem;
    flex: 1;
    justify-content: flex-end;
    color: var(--rhythm-color, #5E6E83) !important;
}

.addIcon:hover {
    color: var(--nightBlue-color, #0C0042) !important;
    cursor: pointer;
}

.title {
    flex: 1;
}