.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .67);
    z-index: 100;
}

.formContainer {
    margin: 1rem !important;
}

.formInput {
    margin: 0.5rem !important;
}

.form {
    margin: 1rem;
}

.saveButton {
    margin: 1rem !important;
}