#wrtc .audioCallerAvatar {
  align-self: center;
  text-align: center;
  background-color: white;
  margin-top: 74px;
  margin-bottom: 100px;
  margin-right: 0;
}
#wrtc .audioCallerAvatar img {
  margin-bottom: 10px;
}

.muteTextWrapper {
  align-self: center;
  position: absolute;
  bottom: 54px;
  text-align: center;
  background: var(--rhythm-color, #5E6E83);
  color: white;
  padding: 0.3em 0.6em;
  border-radius: 6px;
  margin: 0 auto;
  max-width: min-content;
}
#scene-container #wrtc .ui.green.button {
  color: var(--white-color, #ffffff);
  background-color: var(--green-color, #5bc090);
}

#scene-container #wrtc .ui.green.button:hover {
  background-color: var(--seaGreen50-color, #A6F0C5);
}
#wrtc .contactsName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  font-weight: bold;
  margin: 0 auto;
}
