.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .67);
    z-index: 100;
}

.date-input {
    margin: 0.5rem !important;
}

.title-input {
    margin: 0.5rem !important;
}

.plan-input {
    margin: 0.5rem !important;
}

.form {
    margin: 1rem;
}

.cancel-button {
    margin: 1rem !important;
}