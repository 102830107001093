.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .67);
  z-index: 100;
}

.list-item {
  padding-bottom: 1rem !important;
}

.plan {
  display: flex;
}

.planItem {
  margin-right: 2rem;
}

.isActive {
  display: flex;
}

.isActiveSwitch {
  padding-top: .5rem;
  padding-left: 1rem;
}

.list-item-header {
  padding-bottom: 0.5rem !important;
}